import React, { useCallback, useEffect, useRef, useState } from "react";
import WOW from "wowjs";
import styles from "./Promo.module.scss";
import playIcon from "../../../../img/playIcon.svg";
import solanaIcon from "../../../../img/solana.svg";
import unityIcon from "../../../../img/unity.svg";
import webAppIcon from "../../../../img/webAppLanding.svg";
import telegramIcon from "../../../../img/telegramLanding.svg";
import Header from "../../../Header/Header";
import CloseIcon from "../../../../img/close.svg";

import PromoVideo from "../../../../videos/hero.mp4";

function Promo() {
  const videoRef = useRef(null);
  const videoContainerRef = useRef(null);

  const [isShowModal, setIsShowModal] = useState(false);
  const [confettiArray, setConfettiArray] = useState([]);

  const handleScroll = useCallback(() => {
    if (videoContainerRef.current && videoRef.current) {
      const { top, bottom } = videoContainerRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (top < windowHeight * 0.9 && bottom > windowHeight * 0.1) {
        videoRef.current
          .play()
          .catch((error) => console.error("Video play failed:", error));
      } else {
        videoRef.current.pause();
      }
    }
  }, []);

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  useEffect(() => {
    const newConfettiArray = [];
    const baseDuration = 4;

    for (let i = 149; i > -1; i--) {
      const randomLeftStart = Math.random() * 100;
      const randomLeftEnd = randomLeftStart + Math.random() * 15;
      const randomTopEnd = 110;
      const randomWidth = Math.random() * 8;
      const randomHeight = randomWidth * 0.4;
      const randomDelay = i * (baseDuration / 150);
      const randomColor = `hsl(${Math.random() * 360}, 100%, 50%)`;

      const keyframes = `
        @keyframes drop-${i} {
          100% {
            top: ${randomTopEnd}%;
            left: ${randomLeftEnd}%;
          }
        }
      `;
      const styleSheet = document.styleSheets[0];
      styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

      newConfettiArray.push(
        <div
          key={i}
          className={styles.confetti}
          style={{
            left: `${randomLeftStart}%`,
            animation: `drop-${i} ${baseDuration}s linear infinite`,
            animationDelay: `${randomDelay}s`,
            position: "absolute",
            top: "-10%",
            opacity: Math.random() + 0.5,
            transform: `rotate(${Math.random() * 360}deg)`,
            width: `${randomWidth}px`,
            height: `${randomHeight}px`,
            backgroundColor: randomColor,
          }}
        />
      );
    }

    setConfettiArray(newConfettiArray);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <div>
      <Header />
      <div className={styles.promo}>
        <div className="container">
          <div className={styles.hero}>
            <video autoPlay loop muted>
              <source src={PromoVideo} type="video/mp4" />
            </video>
            <div className={styles.wrapper}>{confettiArray}</div>
            <div className={styles.hero__bg}></div>
            <div className={styles.hero__container}>
              <div className={styles.hero__title}>
                Become a Hero of Web3 Battle Royale
              </div>
              <div className={styles.hero__description}>
                Electrifying mix of battle royale, platformer, and arcade
                action. Compete with 32 players in fast-paced elimination
                rounds. Will you rise to the top? Join now and prove your
                prowess!
              </div>
              <div className={styles.hero__wrapper}>
                <div className={styles.hero__btn_wrapper}>
                  <div className={styles.hero__btn}>Go To Waitlist</div>
                </div>
                <div
                  className={styles.hero__watch}
                  onClick={() => setIsShowModal(true)}
                >
                  <img src={playIcon} alt="playIcon" />
                  <div>Watch trailer</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.available}>
            <div>
              <img
                className="wow animate__animated animate__fadeInUp"
                src={solanaIcon}
                alt="solanaIcon"
              />
            </div>
            <div>
              <img
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.25s"
                src={unityIcon}
                alt="unityIcon"
              />
            </div>
            <div>
              <img
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.5s"
                src={webAppIcon}
                alt="webAppIcon"
              />
            </div>
            <div>
              <img
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.75s"
                src={telegramIcon}
                alt="telegramIcon"
              />
            </div>
          </div>
          <div className={styles.about}>
            <div className={styles.about__title}>About Hero Guys</div>
            <div className={styles.about__description}>
              Electrifying mix of battle royale, platformer, and arcade action.
              Compete with 60 players in fast-paced elimination rounds. Will you
              rise to the top? Join now and prove your prowess!
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bg} ref={videoContainerRef}>
        <video autoPlay loop muted ref={videoRef}>
          <source src={PromoVideo} type="video/mp4" />
        </video>
      </div>
      {isShowModal ? (
        <div className={styles.modal}>
          <div className={styles.modal__container}>
            <div
              className={styles.modal__button}
              onClick={() => setIsShowModal(false)}
            >
              <div>Close</div>
              <img src={CloseIcon} alt="CloseIcon" />
            </div>
            <video autoPlay controls>
              <source src={PromoVideo} type="video/mp4" />
            </video>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Promo;
